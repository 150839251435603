import React from 'react'
import style from './EquipmentThermoplastics.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const EquipmentThermoplastics = () => {
  const { t } = useTranslation()
  return (
    <div className={style.engineering} id="engineering">
      <div className={`${'customContainer'} ${style.engineering_wrap}`}>
        <h2 className={style.title}>{t('equipmentThermoplastics.title')}</h2>
        <p className={style.desc}>{t('equipmentThermoplastics.desc')}</p>
        <Link to={'/engineering/thermoplastic'} className={style.button}>
          {t('equipmentThermoplastics.button')}
        </Link>
      </div>
    </div>
  )
}

export default EquipmentThermoplastics
