import React from 'react'
import style from './EquipmentCarbon.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const EquipmentCarbon = () => {
  const { t } = useTranslation()
  return (
    <div className={style.engineering} id="engineering">
      <div className={`${'customContainer'} ${style.engineering_wrap}`}>
        <h2 className={style.title}>{t('equipmentCarbon.title')}</h2>
        <p className={style.desc}>{t('equipmentCarbon.desc')}</p>
        <Link to={'/engineering/carbon-fiber'} className={style.button}>
          {t('engineering.button')}
        </Link>
      </div>
    </div>
  )
}

export default EquipmentCarbon
