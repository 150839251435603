import React from 'react'
import StoneMain from './StoneMain/StoneMain'
import StoneHorizontalCenters from './StoneHorizontalСenters/StoneHorizontalСenters'
import StoneVertical from './StoneVertical/StoneVertical'
import StoneTables from './StoneTables/StoneTables'
import StoneWaterjet from './StoneWaterjet/StoneWaterjet'
import ContactsBlock from '../ContactsBlock/ContactsBlock'
import NewsPreview from '../../Modules/NewsBlock/NewsPreview'

const Index = () => {
  return (
    <div>
      <StoneMain />
      <StoneHorizontalCenters />
      <StoneVertical />
      <StoneTables />
      <StoneWaterjet />
      <NewsPreview />
      <ContactsBlock />
    </div>
  )
}

export default Index
