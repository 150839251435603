import React from 'react'
import EngineeringTechnology from './EngineeringTechnology/EngineeringTechnology'
import EquipmentCarbon from './EquipmentCarbon/EquipmentCarbon'
import EquipmentThermoplastics from './EquipmentThermoplastics/EquipmentThermoplastics'
import EquipmentMetalworking from './EquipmentMetalworking/EquipmentMetalworking'
import EquipmentStone from './EquipmentStone/EquipmentStone'
import ContactsBlock from '../ContactsBlock/ContactsBlock'
import NewsPreview from '../../Modules/NewsBlock/NewsPreview'

const EquipmentPage = () => {
  return (
    <div>
      <EngineeringTechnology />
      <EquipmentCarbon />
      <EquipmentThermoplastics />
      <EquipmentMetalworking />
      <EquipmentStone />
      <NewsPreview />
      <ContactsBlock />
    </div>
  )
}

export default EquipmentPage
