import React from 'react'
import ThermoplasticMain from './ThermoplasticMain/ThermoplasticMain'
import MachiningCenters from './MachiningCenters/MachiningCenters'
import Thermopress from './Thermopress/Thermopress'
import PrintingSystems from './PrintingSystems/PrintingSystems'
import CuttingStations from './CuttingStations/CuttingStations'
import WaterjetSystems from './WaterjetSystems/WaterjetSystems'
import NewsPreview from '../../Modules/NewsBlock/NewsPreview'
import ContactsBlock from '../ContactsBlock/ContactsBlock'

const Index = () => {
  return (
    <div>
      <ThermoplasticMain />
      <MachiningCenters />
      <Thermopress />
      <PrintingSystems />
      <CuttingStations />
      <WaterjetSystems />
      <NewsPreview />
      <ContactsBlock />
    </div>
  )
}

export default Index
