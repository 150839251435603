import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styles from './NewsPage.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NewsPreview = () => {
  const [posts, setPosts] = useState([])
  const { t } = useTranslation()

  const accessToken =
    'EAAHXKkqkZAgEBO2MAeYeWhSu1t4vOuKlrZAr5c94IqkzsGB7ccOjWZAC8YsU89ahfVSHyke1n5AZCmWjvhS2QWVsKCPMtbVhw7ZAuLtHwfnuIViuMjJk4u6XrOB0OphR9Y5bESmSGE4P2FmCB63bMnFW1kZBY8lZAl9a2CWu2w5CZArynhmKoZCOi8XipKYyZCVZBNjtn0UCQ2RApwxc30ZD'
  const userId = '113209753721571'

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        let postsArray = []
        let nextPageUrl = `https://graph.facebook.com/v20.0/${userId}/posts?fields=message,full_picture,from,permalink_url,created_time&access_token=${accessToken}`

        while (nextPageUrl && postsArray.length < 10) {
          const response = await axios.get(nextPageUrl)
          postsArray = [...postsArray, ...response.data.data]
          nextPageUrl = response.data.paging?.next || null
        }

        // Фільтруємо по #important та обмежуємо 3 постами
        const filtered = postsArray.filter(
          (post) => post.message && post.message.includes('#important'),
        )
        setPosts(filtered.slice(0, 3))
      } catch (error) {
        console.error('Error fetching posts:', error)
      }
    }

    fetchPosts()
  }, [])

  return (
    <div className={styles.posts}>
      <div className={`${'customContainer'} ${styles.text}`}>
        <h2 className={styles.title}>{t('news.title')}</h2>
        <p className={styles.desc}>{t('news.desc')}</p>
      </div>

      <div className={`${'customContainer'} ${styles.postsGrid}`}>
        {posts.map((post) => (
          <Link
            to={post.permalink_url}
            target={'_blank'}
            key={post.id}
            className={styles.postItem}
          >
            {post.full_picture && (
              <img
                src={post.full_picture}
                alt="Post"
                className={styles.postImage}
              />
            )}
            <p className={styles.postAuthor}>
              {new Date(post.created_time).toLocaleDateString()}
            </p>
            <p className={styles.postMessage}>{post.message || 'Без опису'}</p>
          </Link>
        ))}
      </div>
      <div className={styles.btn_blocks}>
        <Link to="/news" className={styles.button}>
          {t('newsBBlock.button')}
        </Link>
      </div>
    </div>
  )
}

export default NewsPreview
