import React from 'react'
import CarbonMain from './CarbonMain/CarbonMain'
import MachiningCentersCarbon from './MachiningCentersCarbon/MachiningCentersCarbon'
import CuttingMachines from './CuttingMachines/CuttingMachines'
import HybridSystemsCarbon from './HybridSystems/HybridSystemsCarbon'
import AutoclavesCarbon from './AutoclavesCarbon/AutoclavesCarbon'
import HydraulicCarbon from './HydraulicPressCarbon/HydraulicCarbon'
import ContactsBlock from '../ContactsBlock/ContactsBlock'
import NewsPreview from '../../Modules/NewsBlock/NewsPreview'

const Index = () => {
  return (
    <div>
      <CarbonMain />
      <MachiningCentersCarbon />
      <CuttingMachines />
      <HybridSystemsCarbon />
      <AutoclavesCarbon />
      <HydraulicCarbon />
      <NewsPreview />
      <ContactsBlock />
    </div>
  )
}

export default Index
