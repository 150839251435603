import './App.css'
import './Assets/Fonts/stylesheet.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './Pages/MainPage/MainPage'
import Header from './Modules/Header/Header'
import Footer from './Modules/Footer/Footer'
import MissionPage from './Pages/MissionPage/MissionPage'
import InprogressPage from './Pages/InprogressPage/InprogressPage'
import Contact from './Pages/Contacts/Contacts'
import NewsPage from './Pages/NewsPage/NewsPage'
import EquipmentPage from './Pages/EquipmentPage/EquipmentPage'
import ThermoplasticPage from './Pages/ThermoplasticPage'
import CarbonFiberPage from './Pages/CarbonFiberPage'
import MetalworkingPage from './Pages/MetalworkingPage'
import StonePage from './Pages/StonePage'
import { ScrollToTop } from './Components/ScrollToTop/ScrollToTop'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/mission" element={<MissionPage />} />
          <Route path="/inprogress" element={<InprogressPage />} />
          <Route path="/contacts" element={<Contact />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/engineering" element={<EquipmentPage />} />
          <Route
            path="/engineering/thermoplastic"
            element={<ThermoplasticPage />}
          />
          <Route
            path="/engineering/carbon-fiber"
            element={<CarbonFiberPage />}
          />
          <Route
            path="/engineering/metalworking"
            element={<MetalworkingPage />}
          />
          <Route path="/engineering/glass-stone" element={<StonePage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
