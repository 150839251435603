// import { useState } from 'react'
// import { Modal } from 'react-bootstrap'
// import { PhoneInput } from 'react-international-phone'
// import 'react-international-phone/style.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import './contactPopUp.css'
// import { useTranslation } from 'react-i18next'
//
// const ContactsPopup = ({ show, onHide }) => {
//   const { t } = useTranslation()
//   const [formData, setFormData] = useState({
//     name: '',
//     phone: '',
//     email: '',
//     comment: '',
//     // to: 'a7fe8adf.itton.onmicrosoft.com@emea.teams.ms',
//     to: 'sasha236541@gmail.com',
//   })
//   const [notifications, setNotifications] = useState([])
//
//   const handleChange = (e) => {
//     const { name, value } = e.target
//     setFormData({ ...formData, [name]: value })
//   }
//
//   const handlePhoneChange = (value) => {
//     setFormData({ ...formData, phone: value })
//   }
//
//   const showNotification = (message) => {
//     setNotifications((prev) => [...prev, message])
//     setTimeout(() => {
//       setNotifications((prev) => prev.filter((msg) => msg !== message))
//     }, 3000)
//   }
//
//   const validateForm = () => {
//     const newErrors = {}
//
//     // Email validation
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
//     if (!emailRegex.test(formData.email)) {
//       newErrors.email = t('form.alarmEmail')
//       showNotification(newErrors.email)
//     }
//
//     return Object.keys(newErrors).length === 0
//   }
//
//   const handleSubmit = async (e) => {
//     e.preventDefault()
//
//     if (!validateForm()) {
//       return
//     }
//
//     try {
//       const response = await fetch(
//         'https://prostir3dmailer.balanceglobal.com.ua/post',
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(formData),
//         },
//       )
//
//       if (!response.ok) {
//         throw new Error('Network response was not ok')
//       }
//
//       const data = await response.json()
//       console.log(data)
//       reset()
//       showNotification(t('form.formSend'))
//       setTimeout(() => onHide(), 2000) // Close the popup after successful submission
//     } catch (error) {
//       console.error('Error sending form data:', error)
//       showNotification(t('form.formdontSend'))
//     }
//   }
//
//   const reset = () => {
//     setFormData({
//       name: '',
//       phone: '',
//       email: '',
//       comment: '',
//       to: 'a7fe8adf.itton.onmicrosoft.com@emea.teams.ms',
//     })
//   }
//
//   return (
//     <Modal
//       show={show}
//       onHide={onHide}
//       centered
//       dialogClassName="contacts-popup-modal"
//     >
//       <Modal.Body className="contacts-popup">
//         <div className="form-wrap">
//           <h2 className="contacts-title">{t('form.title')}</h2>
//
//           {notifications.map((msg, index) => (
//             <div key={index} className="notification">
//               {msg}
//             </div>
//           ))}
//
//           <form onSubmit={handleSubmit}>
//             <div className="inputGroup">
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//                 placeholder={t('form.name')}
//                 className="input"
//               />
//             </div>
//
//             <div className="inputGroup">
//               <PhoneInput
//                 defaultCountry={'ua'}
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handlePhoneChange}
//                 required
//                 placeholder={t('form.phone')}
//                 className="input"
//               />
//             </div>
//
//             <div className="inputGroup">
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//                 placeholder={t('form.email')}
//                 className="input"
//               />
//             </div>
//
//             <div className="inputGroup">
//               <input
//                 id="comment"
//                 name="comment"
//                 value={formData.comment}
//                 onChange={handleChange}
//                 placeholder={t('form.comment')}
//                 className="input"
//               />
//             </div>
//
//             <button className="button" type="submit">
//               {t('form.button')}
//             </button>
//           </form>
//         </div>
//       </Modal.Body>
//     </Modal>
//   )
// }
//
// export default ContactsPopup

// import { useState } from 'react'
// import { Modal } from 'react-bootstrap'
// import { PhoneInput } from 'react-international-phone'
// import 'react-international-phone/style.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import './contactPopUp.css'
// import { useTranslation } from 'react-i18next'
// import { CloseBtn, PopUpLogo } from '../../Assets/Images/ContactsPage'
//
// const ContactsPopup = ({ show, onHide, title }) => {
//   const { t } = useTranslation()
//   const [formData, setFormData] = useState({
//     name: '',
//     phone: '',
//     email: '',
//     comment: '',
//     // to: 'a7fe8adf.itton.onmicrosoft.com@emea.teams.ms',
//     to: 'sasha236541@gmail.com',
//   })
//   const [notifications, setNotifications] = useState([])
//
//   const handleChange = (e) => {
//     const { name, value } = e.target
//     setFormData({ ...formData, [name]: value })
//   }
//
//   const handlePhoneChange = (value) => {
//     setFormData({ ...formData, phone: value })
//   }
//
//   const showNotification = (message) => {
//     setNotifications((prev) => [...prev, message])
//     setTimeout(() => {
//       setNotifications((prev) => prev.filter((msg) => msg !== message))
//     }, 3000)
//   }
//
//   const validateForm = () => {
//     const newErrors = {}
//
//     // Email validation
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
//     if (!emailRegex.test(formData.email)) {
//       newErrors.email = t('form.alarmEmail')
//       showNotification(newErrors.email)
//     }
//
//     return Object.keys(newErrors).length === 0
//   }
//
//   const handleSubmit = async (e) => {
//     e.preventDefault()
//
//     if (!validateForm()) {
//       return
//     }
//
//     try {
//       const response = await fetch(
//         'https://prostir3dmailer.balanceglobal.com.ua/post',
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(formData),
//         },
//       )
//
//       if (!response.ok) {
//         throw new Error('Network response was not ok')
//       }
//
//       const data = await response.json()
//       console.log(data)
//       reset()
//       showNotification(t('form.formSend'))
//       setTimeout(() => onHide(), 2000) // Close the popup after successful submission
//     } catch (error) {
//       console.error('Error sending form data:', error)
//       showNotification(t('form.formdontSend'))
//     }
//   }
//
//   const reset = () => {
//     setFormData({
//       name: '',
//       phone: '',
//       email: '',
//       comment: '',
//       // to: 'a7fe8adf.itton.onmicrosoft.com@emea.teams.ms',
//       to: 'sasha236541@gmail.com',
//     })
//   }
//
//   return (
//     <Modal
//       show={show}
//       onHide={onHide}
//       centered
//       dialogClassName="contacts-popup-modal"
//     >
//       <Modal.Body className="contacts-popup">
//         <div
//           className="close_text_block"
//           onClick={onHide}
//           style={{ cursor: 'pointer' }}
//         >
//           <p className="close_text">Закрити вікно</p>
//           <img src={CloseBtn} alt="CloseBtn" />
//         </div>
//
//         <div className="form-wrap">
//           <h2 className="contacts-title">{title || t('form.title')}</h2>
//
//           {notifications.map((msg, index) => (
//             <div key={index} className="notification">
//               {msg}
//             </div>
//           ))}
//
//           <form onSubmit={handleSubmit}>
//             <div className="inputGroup">
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//                 placeholder={t('form.name')}
//                 className="input"
//               />
//             </div>
//
//             <div className="inputGroup">
//               <PhoneInput
//                 defaultCountry={'ua'}
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handlePhoneChange}
//                 required
//                 placeholder={t('form.phone')}
//                 className="input"
//               />
//             </div>
//
//             <div className="inputGroup">
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//                 placeholder={t('form.email')}
//                 className="input"
//               />
//             </div>
//
//             <div className="inputGroup">
//               <input
//                 id="comment"
//                 name="comment"
//                 value={formData.comment}
//                 onChange={handleChange}
//                 placeholder={t('form.comment')}
//                 className="input"
//               />
//             </div>
//
//             <button className="button" type="submit">
//               {t('form.button')}
//             </button>
//
//             <img className="popup_logo" src={PopUpLogo} alt="PopUpLogo" />
//           </form>
//         </div>
//       </Modal.Body>
//     </Modal>
//   )
// }
//
// export default ContactsPopup

import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './contactPopUp.css'
import { useTranslation } from 'react-i18next'
import { CloseBtn, PopUpLogo2 } from '../../Assets/Images/ContactsPage'

const ContactsPopup = ({ show, onHide, title }) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    comment: '',
    to: 'a7fe8adf.itton.onmicrosoft.com@emea.teams.ms',
  })
  const [notifications, setNotifications] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value })
  }

  const showNotification = (message) => {
    setNotifications((prev) => [...prev, message])
    setTimeout(() => {
      setNotifications((prev) => prev.filter((msg) => msg !== message))
    }, 3000)
  }

  const validateForm = () => {
    const newErrors = {}

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(formData.email)) {
      newErrors.email = t('form.alarmEmail')
      showNotification(newErrors.email)
    }

    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    // Append page URL and popup title to the comment
    const currentPageUrl = window.location.href
    const currentPopupTitle = title || t('form.title')
    const userComment = formData.comment
    const enhancedComment = `${userComment}\n\nPage URL: ${currentPageUrl}\nPopup Title: ${currentPopupTitle}`

    // Create submission data with enhanced comment
    const submissionData = {
      ...formData,
      comment: enhancedComment,
    }

    try {
      const response = await fetch(
        'https://prostir3dmailer.balanceglobal.com.ua/post',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submissionData),
        },
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      console.log(data)
      reset()
      showNotification(t('form.formSend'))
      setTimeout(() => onHide(), 2000) // Close the popup after successful submission
    } catch (error) {
      console.error('Error sending form data:', error)
      showNotification(t('form.formdontSend'))
    }
  }

  const reset = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
      comment: '',
      to: 'a7fe8adf.itton.onmicrosoft.com@emea.teams.ms',
    })
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName="contacts-popup-modal"
    >
      <Modal.Body className="contacts-popup">
        <div
          className="close_text_block"
          onClick={onHide}
          style={{ cursor: 'pointer' }}
        >
          <p className="close_text">Закрити вікно</p>
          <img src={CloseBtn || '/placeholder.svg'} alt="CloseBtn" />
        </div>

        <div className="form-wrap">
          <h2 className="contacts-title">{title || t('form.title')}</h2>

          {notifications.map((msg, index) => (
            <div key={index} className="notification">
              {msg}
            </div>
          ))}

          <form onSubmit={handleSubmit}>
            <div className="inputGroup">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder={t('form.name')}
                className="input"
              />
            </div>

            <div className="inputGroup">
              <PhoneInput
                defaultCountry={'ua'}
                name="phone"
                value={formData.phone}
                onChange={handlePhoneChange}
                required
                placeholder={t('form.phone')}
                className="input"
              />
            </div>

            <div className="inputGroup">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder={t('form.email')}
                className="input"
              />
            </div>

            <div className="inputGroup">
              <input
                id="comment"
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                placeholder={t('form.comment')}
                className="input"
              />
            </div>

            <button className="button" type="submit">
              {t('form.button')}
            </button>

            <img className="popup_logo" src={PopUpLogo2} alt="PopUpLogo" />
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ContactsPopup
