// import React from 'react'
// import style from './AutoclavesCarbon.module.css'
// import { useTranslation } from 'react-i18next'
// import { Link } from 'react-router-dom'
//
// const AutoclavesCarbon = () => {
//   const { t } = useTranslation()
//   return (
//     <div className={style.engineering} id="engineering">
//       <div className={`${'customContainer'} ${style.engineering_wrap}`}>
//         <h2 className={style.title}>{t('autoclavesCarbon.title')}</h2>
//         <Link to={'http://prostir3d.com'} className={style.button}>
//           {t('autoclavesCarbon.button')}
//         </Link>
//       </div>
//     </div>
//   )
// }
//
// export default AutoclavesCarbon

import React, { useState } from 'react'
import style from './AutoclavesCarbon.module.css'
import { useTranslation } from 'react-i18next'
import ContactsPopup from '../../../Components/PopUp/ContactPopUp'

const AutoclavesCarbon = () => {
  const { t } = useTranslation()
  const [showPopup, setShowPopup] = useState(false)

  const handleOpenPopup = () => setShowPopup(true)
  const handleClosePopup = () => setShowPopup(false)

  const titleText = t('autoclavesCarbon.title')

  return (
    <div className={style.engineering} id="engineering">
      <div className={`${'customContainer'} ${style.engineering_wrap}`}>
        <h2 className={style.title}>{titleText}</h2>
        <button onClick={handleOpenPopup} className={style.button}>
          {t('autoclavesCarbon.button')}
        </button>
      </div>
      <ContactsPopup
        show={showPopup}
        onHide={handleClosePopup}
        title={titleText}
      />
    </div>
  )
}

export default AutoclavesCarbon
